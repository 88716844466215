<template>
    <div>
        <v-container class="a-container">
            <div :class="mobileCentering" class="display-1 primary-text font-weight-bold mb-2 mt-3">Allocation</div>
            <v-divider></v-divider>
             <v-row v-if="!isMobile" class="mt-3" :justify="mobileFilterCentering">
                 <v-col cols="12" sm="3">
                    <v-select
                        v-model="filters.viewBy"
                        label="View Allocation By"
                        :items="viewByOptions"
                    >

                    </v-select>
                </v-col>
                <v-col cols="12" sm="3">
                    <clearable-chips-selector
                        label="Group"
                        v-model="selectedGroupFilter"
                        :items="groups"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="3">
                    <clearable-chips-selector
                        label="Manager"
                        v-model="selectedManagerFilter"
                        :items="managers"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12" sm="3">
                    <clearable-chips-selector
                        :items="accountIds"
                        label="Account"
                        v-model="selectedAccountFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                 <v-col cols="12" sm="6" md="3">
                    <clearable-chips-selector
                        :items="banks"
                        label="Custodian"
                        v-model="selectedBankFilter"
                     >
                     </clearable-chips-selector>
                 </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    lg="6"
                >
                    <allocation-donut-card
                        :data="filteredConsPosNominalFilter"
                        :view-by="filters.viewBy"
                        id="allocation"
                        :clickable-chart="true"
                    ></allocation-donut-card>
                </v-col>
                <v-col
                    cols="12"
                    lg="6"
                >
                    <allocation-bar-card
                        :data="filteredConsPosNominalFilter"
                        :view-by="filters.viewBy"
                        id="allocation"
                    >

                    </allocation-bar-card>
                </v-col>
                <v-col cols="12">
                    <allocation-extended-table-card
                        :data="filteredConsPosNominalFilter"
                        :view-by="filters.viewBy"
                        id="allocation"
                    >
                    </allocation-extended-table-card>
                </v-col>
            </v-row>
        </v-container>
        <mobile-filter-pane>
            <template v-slot:filters>
                <v-col cols="12">
                    <v-select
                        v-model="filters.viewBy"
                        label="View Allocation By"
                        :items="viewByOptions"
                    >

                    </v-select>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        label="Group"
                        v-model="selectedGroupFilter"
                        :items="groups"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        label="Manager"
                        v-model="selectedManagerFilter"
                        :items="managers"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        :items="accountIds"
                        label="Account"
                        v-model="selectedAccountFilter"
                     >
                     </clearable-chips-selector>
                </v-col>
                <v-col cols="12">
                    <clearable-chips-selector
                        :items="banks"
                        label="Custodian"
                        v-model="selectedBankFilter"
                     >
                     </clearable-chips-selector>
                 </v-col>
            </template>
        </mobile-filter-pane>
    </div>
</template>

<script>
    import AllocationDonutCard from '../../components/reports/AllocationDonutCard'
    import AllocationBarCard from '../../components/reports/AllocationBarCard'
    import AllocationExtendedTableCard from '../../components/reports/AllocationExtendedTableCard'
    import ClearableChipsSelector from '../../components/base/filters/ClearableChipsSelector'
    import {mapState, mapGetters} from 'vuex'
    import MobileFilterPane from '../../components/base/filters/MobileFilterPane'


    export default {
        name: "Allocations",
        components: {
            AllocationDonutCard, AllocationBarCard,
            AllocationExtendedTableCard, ClearableChipsSelector,
            MobileFilterPane
        },
        data() {
            return {
                viewByOptions: [
                    {text: 'Custodian', value: 'custodian'},
                    {text: 'Asset Class', value: 'category'},
                    {text: 'Account', value: 'portfolio'},

                    {text: 'Industry', value: 'industry'},
                    {text: 'Geolocation', value: 'geolocation'},
                    {text: 'Currency', value: 'currency'}
                ],
                filters: {
                    viewBy: 'category'
                }
            }
        },
        mounted(){
            this.$store.dispatch('setAllocationChartFilter', null)
        },
        computed: {
            isMobile() {
                return this.$vuetify.breakpoint.mdAndDown
            },

            mobileCentering() {
                return this.isMobile ? 'text-center' : ''
            },

            mobileFilterCentering() {
                return this.isMobile ? 'center' : null
            },
            filteredConsPosNominalFilter() {
                return this.filteredConsPos.map(x => x).filter(x => {
                    return x.nominal_amount !== 0
                })
            },
            ...mapGetters(['accountIds', 'groups', 'managers', 'filteredConsPos', 'banks']),
            ...mapState(['groupFilter', 'managerFilter', 'accountFilter', 'bankFilter']),
            selectedGroupFilter: {
                set(groups){
                    this.$store.commit('setGroupFilter', groups)
                },
                get(){
                    return this.groupFilter
                }
            },
            selectedManagerFilter: {
                set(managers){
                    this.$store.commit('setManagerFilter', managers)
                },
                get(){
                    return this.managerFilter
                }
            },
            selectedAccountFilter: {
                set(accounts){
                    this.$store.commit('setAccountFilter', accounts)
                },
                get(){
                    return this.accountFilter
                }
            },
            selectedBankFilter: {
                set(accounts){
                    this.$store.commit('setBankFilter', accounts)
                },
                get(){
                    return this.bankFilter
                }
            },
        }
    }
</script>

<style scoped>

</style>