<template>
    <report-summary-card
        :report-title="cardTitleText"
        :loading="loading"
        report-link=""
    >
        <template v-slot:chart style="height: 100%;">
            <allocation-bars
                :data="data"
                :view-by="viewBy"
                :id="id"
            ></allocation-bars>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import AllocationBars from '../reports/AllocationBars';

    export default {
        name: "AllocationBarCard",
        props: [
            'data', 'viewBy', 'id'
        ],
        components: {
            ReportSummaryCard, AllocationBars
        },
        data() {
            return {
                viewByNameMap: {
                    portfolio: 'Account',
                    category: 'Asset Class',
                    custodian: 'Custodian',
                    industry: 'Industry',
                    geolocation: 'Geolocation',
                }
            }
        },
        computed: {
             loading() {
                return this.$store.getters.consPosLoading
            },

            cardTitleText() {
                 return 'Allocation Totals by ' + this.viewByNameMap[this.viewBy]
            }
        }
    }
</script>

<style scoped>

</style>