<template>
    <div class="chart-box clickable" >
        <canvas :id="'allocation-bars-' + id"></canvas>
    </div>
</template>

<script>
    import {aggregateConsPosDataByCategory, aggregateConsPosDataBySubCategoryForAllocation} from "../../utils/data.utils";
    import {currencyFormatter} from "../../utils/format.utils";
    import {sortBy} from "../../utils/data.utils";
    import Chart from 'chart.js'
    import ChartDataLabels from 'chartjs-plugin-datalabels';

    export default {
        name: "AllocationBars",
        props: [
            'data', 'viewBy', 'id'
        ],
        mounted() {
            let ctx = document.getElementById('allocation-bars-' + this.id);
            ctx.height = 200;
            this.chart = new Chart(ctx, {
                plugins: [ChartDataLabels],
                type: 'horizontalBar',
                data: {
                    labels: this.chartFormattedLabels,
                    datasets: [{
                        label: 'USD Value',
                        data: this.chartFormattedData,
                        backgroundColor: this.chartFormattedColors.map(x => x),
                        borderColor: this.chartFormattedColors.map(x => x),
                        barPercentage: .8
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    legend: {
                        display: false
                    },

                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display:false,
                                beginAtZero: true
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display: false
                            }
                        }]
                    },

                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let dataset = data.datasets[tooltipItem.datasetIndex];
                                let currentValue = dataset.data[tooltipItem.index];
                                let indice = tooltipItem.index;
                                return  data.labels[indice] +': ' + currencyFormatter.format(currentValue);
                            }
                        }
                    },

                    plugins: {
                        datalabels: {
                            clamp: true,
                            anchor: 'end',
                            align: function(context){
                                let maxValue = Math.max.apply(null, context.dataset.data);
                                let midPoint = maxValue / 2;
                                return context.dataset.data[context.dataIndex] > midPoint ? 'start' : 'end'
                            },
                            color: function(context){
                                let maxValue = Math.max.apply(null, context.dataset.data);
                                let midPoint = maxValue / 2;
                                return context.dataset.data[context.dataIndex] > midPoint ? 'white' : 'black'
                            },
                            formatter: function (value, context){

                                let data = context.dataset.data;
                                let totalValue = 0;
                                data.forEach(record => {
                                    totalValue += parseFloat(record)
                                });

                                let percent = (value / totalValue * 100).toFixed(2) + '%';
                                return currencyFormatter.format(value) + ' (' + percent +  ')'
                            }
                        }
                    }
                }
            });

            ctx.onclick = (evt) => {
                let activePoints = this.chart.getElementAtEvent(evt);

                // If they click directly on a bar
                if (activePoints[0]){
                    let activeIdx = activePoints[0]['_index'];
                    // Set the filter
                    if (this.viewBy === 'category'){
                        let subCategoryLabel = this.chart.data.labels[activeIdx];
                        let categoryLabel = this.subCategoryCategoryMap[subCategoryLabel];
                        this.$store.dispatch('setAllocationChartFilter', categoryLabel);
                        this.$store.dispatch('setAllocationSelectedSubCategory', subCategoryLabel);
                    } else {
                        let label = this.chart.data.labels[activeIdx];
                        this.$store.dispatch('setAllocationChartFilter', label);
                        this.$store.dispatch('setAllocationSelectedSubCategory', null);
                    }

                // If they clicked anywhere but a bar
                } else {
                    let mousePoint = Chart.helpers.getRelativePosition(evt, this.chart.chart);
                    let minXPointOnChart = this.chart.scales['x-axis-0'].left;
                    let xClickPoint = mousePoint.x;

                    // If they clicked in the chart area (but not on a bar)
                    if (xClickPoint >= minXPointOnChart) {
                        // Set the chart filter back to null
                        this.$store.dispatch('setAllocationChartFilter', null);
                        this.$store.dispatch('setAllocationSelectedSubCategory', null);

                    // If they clicked in the axis label area, assume they meant to click on a label
                    } else {
                        let activeIdx = this.chart.scales['y-axis-0'].getValueForPixel(mousePoint.y);
                        // set the filter
                        if (this.viewBy === 'category'){
                            let subCategoryLabel = this.chart.data.labels[activeIdx];
                            let categoryLabel = this.subCategoryCategoryMap[subCategoryLabel];
                            this.$store.dispatch('setAllocationChartFilter', categoryLabel);
                            this.$store.dispatch('setAllocationSelectedSubCategory', subCategoryLabel);
                        } else {
                            let label = this.chart.data.labels[activeIdx];
                            this.$store.dispatch('setAllocationChartFilter', label);
                            this.$store.dispatch('setAllocationSelectedSubCategory', null);
                        }
                    }
                }
            }
        },
        watch: {
            aggregateData: function () {

                this.chart.data.labels = this.chartFormattedLabels;
                this.chart.data.datasets[0].data = this.chartFormattedData;
                this.chart.data.datasets[0].backgroundColor = this.chartFormattedColors.map(x => x);
                this.chart.update();
                this.chart.resize()
            },

            chartFilter: function (){

                if (this.allocationChartFilter !== null){

                    if (this.viewBy === 'category'){

                        let labels = this.chart.data.labels;

                        if (this.allocationSelectedSubCategory === null) {
                            let selectedCategory = this.allocationChartFilter;
                            let subCategoriesInCategory = this.categorySubCategoryMap[selectedCategory];

                            // Change the colors
                            let backgroundColors = this.chart.data.datasets[0].backgroundColor;
                            for (let i = 0; i < backgroundColors.length; i++){
                                if (!subCategoriesInCategory.includes(labels[i])) {
                                    backgroundColors[i] = 'lightgray';
                                } else {
                                    backgroundColors[i] = this.chartFormattedColors[i]
                                }
                            }
                        } else {

                            let selectedSubCategory = this.allocationSelectedSubCategory;

                            // Change the colors
                            let backgroundColors = this.chart.data.datasets[0].backgroundColor;
                            for (let i = 0; i < backgroundColors.length; i++){
                                if (!(selectedSubCategory === labels[i])) {
                                    backgroundColors[i] = 'lightgray';
                                } else {
                                    backgroundColors[i] = this.chartFormattedColors[i]
                                }
                            }

                        }


                        // backgroundColors[activeIdx] = this.chartFormattedColors[activeIdx];

                    } else {

                        let activeIdx = this.chart.data.labels.indexOf(this.allocationChartFilter);
                        // Change the colors
                        let backgroundColors = this.chart.data.datasets[0].backgroundColor;
                        for (let i = 0; i < backgroundColors.length; i++){
                            if (i !== activeIdx) {
                                backgroundColors[i] = 'lightgray';
                            } else {
                                backgroundColors[i] = this.chartFormattedColors[i]
                            }
                        }

                        backgroundColors[activeIdx] = this.chartFormattedColors[activeIdx];
                    }

                } else {

                    // Change the colors back
                    let backgroundColors = this.chart.data.datasets[0].backgroundColor;
                    for (let i = 0; i < backgroundColors.length; i++) {
                        backgroundColors[i] = this.chartFormattedColors[i];

                    }
                }

                this.chart.update()
            }
        },
        computed: {
            aggregateData(){

                let newData = this.data.map((x) => x);

                if (this.viewBy==='category'){

                    newData = newData.filter(record => {
                        return record.macro_category.toLowerCase() === 'asset'
                    });

                    return aggregateConsPosDataBySubCategoryForAllocation(newData).filter(record => {
                        return record.usd_amount !== 0;
                    })
                }


                return aggregateConsPosDataByCategory(newData, this.viewBy).filter(record => {
                    return record.usd_amount !== 0;
                })
            },

            subCategoryCategoryMap(){

                let subCategoryCategoryMap = {};
                this.data.map(x => x).forEach(record => {
                    subCategoryCategoryMap[record.sub_category] = record.category
                });
                return subCategoryCategoryMap
            },

            categorySubCategoryMap(){

                let categorySubCategoryMap = {};
                this.data.map(x => x).forEach(record => {
                    if (categorySubCategoryMap[record.category]){
                        let subCategoryArray = categorySubCategoryMap[record.category];
                        if (!subCategoryArray.includes(record.sub_category)){
                            subCategoryArray.push(record.sub_category)
                        }
                    } else {
                        categorySubCategoryMap[record.category] = [];
                        categorySubCategoryMap[record.category].push(record.sub_category)
                    }

                });

                return categorySubCategoryMap

            },
            totalValue(){

                let totalValue = 0;

                this.aggregateData.forEach(record => {
                    totalValue += record.usd_amount
                });

                return totalValue

            },

            sortedData(){
                let newData = this.aggregateData.map(x => x);

                if(this.viewBy === 'category'){
                    let sortFunc = sortBy('categorySorting', 'subCategorySorting', {name: 'usd_amount', reverse: true});
                    return newData.sort(sortFunc)
                }

                let sortFunc = sortBy('sorting', {name: 'usd_amount', reverse: true});
                return newData.sort(sortFunc)
            },

            chartFormattedLabels(){
                let labels = [];
                this.sortedData.forEach(record => {
                    labels.push(record.aggBy)
                });
                return labels
            },

            chartFormattedColors(){
                let colors = [];
                this.sortedData.map(x => x).forEach(record => {
                    colors.push(record.color)
                });
                return colors
            },

            chartFormattedData(){
                let data = [];
                this.sortedData.forEach(record => {
                    data.push((record.usd_amount || 0).toFixed(2))
                });
                return data
            },

            allocationChartFilter(){
                return this.$store.getters.allocationChartFilter
            },

            allocationSelectedSubCategory(){
                return this.$store.getters.allocationSelectedSubCategory
            },

            chartFilter() {
                return this.allocationChartFilter + this.allocationSelectedSubCategory
            }
        }
    }
</script>

<style scoped>

</style>