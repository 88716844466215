<template>
    <div class="tooltip">
        <v-menu
            :value="true"
            @change="$emit('input', show)"
            :close-on-content-click="false"
            :close-on-click="false"
            absolute
            :position-x="positionX + 15"
            :position-y="positionY"
        >
            <v-card>
                <v-data-table
                    :items="data"
                    :headers="tableHeaders"
                    disable-sort
                    hide-default-footer
                    disable-pagination
                    dense
                >
                </v-data-table>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    export default {
        name: "ExtendedTableToolTip",
        props: ['data', 'value', 'positionX', 'positionY', 'projectedIncome'],
        computed: {
            tableHeaders(){
                let headers = [
                    {text: 'Account', value: 'portfolio'},
                    {text: 'Custodian', value: 'custodian'},

                ];

                if(this.projectedIncome){
                    headers.push({text: 'Total Projected Income', value: 'total_pi', align: 'end'})
                } else {
                    headers.push({text: 'Nominal Amt', value: 'nominal_amount', align: 'end'});
                    headers.push({text: 'USD Amt', value: 'usd_amount', align: 'end'});
                }

                return headers
            }
        }
    }
</script>

<style scoped>
.tooltip{
    pointer-events: none;
}
</style>