<template>
    <report-summary-card
        report-title="Allocation Table Details"
        :loading="consPosLoading"
        report-link=""
        :show-search="false"
        @search="searchPositions"
        show-export
        export-file-name="allocation_table_details.csv"
        :export-data="exportData"
    >
        <template v-slot:table>
            <div
                @mouseleave="showTooltip = false"
            >
                <v-data-table
                    :headers="tableHeaders"
                    :loading="consPosLoading"
                    :items="formattedSortedTableData"
                    :search="searchText"
                    no-data-text="No positions found."
                    :mobile-breakpoint="0"
                    :custom-sort="customSort"
                    :options.sync="options"
                    fixed-header
                    :height="formattedSortedTableData.length > 10 ? 525 : 50 + formattedSortedTableData.length * 52.5"
                >
                    <template v-slot:item="{item, headers}">
                        <tr
                            @mouseenter="showTooltip = true"
                        >
                            <td
                                :class="getColumnClasses(header, item)"
                                :key="index" v-for="(header, index) in headers"
                                @click="getToolTipBreakdown(item.macro_category, item.position_name)"
                                @mouseover="getToolTipBreakdown(item.macro_category, item.position_name)"
                                @mousemove="updateToolTipPosition"
                            >
                                {{ item[header.value] }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
            <extended-table-tool-tip
                v-if="showTooltip"
                :data="tooltipData"
                :position-x="positionX"
                :position-y="positionY"
            ></extended-table-tool-tip>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import { currencyFormatter } from "../../utils/format.utils";
    import {sortBy, disaggForTooltip} from '../../utils/data.utils';
    import ExtendedTableToolTip from './ExtendedTableToolTip'
    import {mapGetters} from 'vuex'


    export default {
        name: "AllocationExtendedTableCard",
        props: [
            'data', 'viewBy', 'id'
        ],
        components: {
            ReportSummaryCard, ExtendedTableToolTip
        },
        data() {
            return {
                options: {},
                viewByNameMap: {
                    portfolio: 'Account',
                    category: 'Asset Class',
                    custodian: 'Custodian',
                    industry: 'Industry',
                    geolocation: 'Geolocation',
                    currency: 'Currency'
                },
                searchText: '',
                showTooltip: false,
                tooltipData: [],
                positionX: 0,
                positionY: 0,
                viewByColumnWidths: {
                    portfolio: 140,
                    category: 140,
                    custodian: 110,
                    industry: 140,
                    geolocation: 140,
                    currency: 110
                }
            }
        },
        watch: {
            viewBy: function () {
                this.options.sortBy = [];
                this.options.sortDesc = [];
            }
        },
        methods: {
            searchPositions(searchText){
                this.searchText = searchText
            },

            getToolTipBreakdown(macroCategory, positionName){
                this.updateToolTipPosition();
                this.tooltipData = disaggForTooltip(macroCategory, positionName, this.data.map(x=>x));
            },
            updateToolTipPosition(){
                let posY = event.clientY;
                posY -= 32; // Table header
                posY -= (24 * this.tooltipHeight); // table body height
                posY -= 15; // Buffer
                this.positionY = posY;
                this.positionX = event.clientX;
            },
            getHeaderClass(header){
                return  header.align ? `text-${header.align}` : ''
            },
            getPLColorClass(header, item){

                if(header.value === 'profit_loss'){
                    let profitLoss = item.profit_loss;
                    return profitLoss[0] === '-' ? 'negative-pl' : 'positive-pl'
                }

                return ''

            },
            getColumnClasses(header, item){

                let headerClass = this.getHeaderClass(header);
                let PLColorClass = this.getPLColorClass(header, item);
                return [headerClass, PLColorClass].join(' ');

            },
            customSort(items, index, isDescending) {
                if (index.length === 0) {
                    return items
                }
                const isDesc = !isDescending[0];  // reversed so that ascending sort is really descending
                const sortField = index[0];
                const keys = Object.keys(this.formattedSortedTableData[0] || {});
                const hasRawField = keys.includes(sortField + '_raw');
                let sortFunc;
                if (hasRawField) {
                    const rawField = sortField + '_raw';
                    if (!isDesc) {
                        sortFunc = sortBy(rawField);
                    } else {
                        sortFunc = sortBy({name: rawField, reverse: true})
                    }
                } else {
                    if (!isDesc) {
                        sortFunc = sortBy(sortField);
                  } else {
                        sortFunc = sortBy({name: sortField, reverse: true})
                  }
                }

                return items.sort(sortFunc)
            }

        },
        computed: {
            ...mapGetters(['consPosLoading', 'allocationChartFilter', 'allocationSelectedSubCategory']),
            tooltipHeight(){
                return this.tooltipData.length;
            },
            formattedViewBy() {
                return this.viewByNameMap[this.viewBy]
            },

            totalAssetValue(){
                let totalAssetValue = 0;
                this.data.forEach(record => {
                    if (record.macro_category.toUpperCase() === 'ASSET') totalAssetValue += record.usd_amount
                });

                return totalAssetValue
            },

            totalLiabilityValue(){
                let totalLiabValue = 0;
                this.data.forEach(record => {
                    if (record.macro_category.toUpperCase() === 'LIABILITIES') totalLiabValue += record.usd_amount
                });

                return totalLiabValue
            },

            tableHeaders() {
                if (this.viewBy === 'category'){
                    return [
                        {text: 'Macro Category', value: 'macro_category', width: 140},
                        {text: this.formattedViewBy, value: this.viewBy, width: this.viewByColumnWidths[this.viewBy]},
                        {text: 'SubCategory', value: 'sub_category', width: 140},
                        {text: 'Position', value: 'position_name', width: 400},
                        {text: 'Currency', value: 'currency', width: 140},
                        {text: 'Nominal Amount', value: 'nominal_amount', align: 'right', width: 150},
                        {text: 'Cost Price', value: 'cost_price', align: 'right', width: 150},
                        {text: 'Price', value: 'price', align: 'right', width: 150},
                        {text: 'P/L', value: 'profit_loss', align: 'right', width: 150},
                        {text: 'USD Value', value: 'usd_amount', align: 'right', width: 150},
                        {text: '% of Portfolio', value: 'percent', align: 'right', width: 150}
                    ]
                } else if (this.viewBy === 'currency') {
                    return [
                        {text: 'Macro Category', value: 'macro_category', width: 140},
                        {text: this.formattedViewBy, value: this.viewBy, width: this.viewByColumnWidths[this.viewBy]},
                        {text: 'Position', value: 'position_name', width: 400},
                        {text: 'Nominal Amount', value: 'nominal_amount', align: 'right', width: 150},
                        {text: 'Cost Price', value: 'cost_price', align: 'right', width: 150},
                        {text: 'Price', value: 'price', align: 'right', width: 150},
                        {text: 'P/L', value: 'profit_loss', align: 'right', width: 150},
                        {text: 'USD Value', value: 'usd_amount', align: 'right', width: 150},
                        {text: '% of Portfolio', value: 'percent', align: 'right', width: 150},
                    ]
                }

                return [
                    {text: 'Macro Category', value: 'macro_category', width: 140},
                    {text: this.formattedViewBy, value: this.viewBy, width: this.viewByColumnWidths[this.viewBy]},
                    {text: 'Position', value: 'position_name', width: 400},
                    {text: 'Currency', value: 'currency', width: 140},
                    {text: 'Nominal Amount', value: 'nominal_amount', align: 'right', width: 150},
                    {text: 'Cost Price', value: 'cost_price', align: 'right', width: 150},
                    {text: 'Price', value: 'price', align: 'right', width: 150},
                    {text: 'P/L', value: 'profit_loss', align: 'right', width: 150},
                    {text: 'USD Value', value: 'usd_amount', align: 'right', width: 150},
                    {text: '% of Portfolio', value: 'percent', align: 'right', width: 150},
                ]
            },

            chartFilteredData(){
                if (this.allocationChartFilter !== null){
                    if (this.allocationSelectedSubCategory !== null){
                        return this.data.filter(record => {
                            return record.sub_category === this.allocationSelectedSubCategory
                        })
                    }
                    return this.data.filter(record => {
                        return record[this.viewBy] === this.allocationChartFilter
                    })
                } else {
                    return this.data
                }
            },

            aggregatedData(){

                const result = [...this.chartFilteredData.reduce((r, o) => {
                    const key = o.macro_category + '-' + o[this.viewBy] + '-' + o.position_name;

                    const item = r.get(key) || Object.assign({}, o, {
                        quantity: 0,
                        nominal_amount: 0,
                        cost_price: 0,
                        price: 0,
                        usd_amount: 0,
                        sort_order_macro_category: 1,
                        sort_order_view_by: 1,
                        sort_order_sub_category: 1
                    });

                    item.quantity += o.quantity;
                    item.cost_price += o.cost_price * o.quantity;
                    item.nominal_amount += o.nominal_amount;
                    item.price += o.price * o.quantity;
                    item.usd_amount += o.usd_amount;
                    item.sort_order_macro_category = o.sorting.macro_category;
                    item.sort_order_view_by = o.sorting[this.viewBy];
                    item.sort_order_sub_category = o.sorting.sub_category;

                    return r.set(key, item);

                }, new Map).values()];

                result.forEach(record => {
                    record.cost_price = record.cost_price / record.quantity;
                    record.price = record.price / record.quantity;
                    record.profit_loss = record.cost_price === 0 ? 0 : ((record.price - record.cost_price) / record.cost_price) || 0
                });


                return result
            },

            sortedTableData(){
                let newData = this.aggregatedData.map((x) => x);

                if (this.viewBy === 'category'){
                    let sortFunc = sortBy(
                        'sort_order_macro_category', 'macro_category',
                        'sort_order_view_by', this.viewBy,
                        'sort_order_sub_category', 'sub_category',
                        {name: 'usd_amount', reverse: true});

                    return newData.sort(sortFunc)
                } else{
                    let sortFunc = sortBy(
                        'sort_order_macro_category', 'macro_category',
                        'sort_order_view_by', this.viewBy,
                        {name: 'usd_amount', reverse: true});
                    return newData.sort(sortFunc)
                }

            },

            formattedSortedTableData(){
                 let newData = [];
                 this.sortedTableData.forEach(record => {
                     let totalValue = record.macro_category.toUpperCase() === 'ASSET' ? this.totalAssetValue : this.totalLiabilityValue
                     let percent = record.usd_amount / totalValue * 100;
                     let newRecord = {
                         macro_category: record.macro_category,
                         sub_category: record.sub_category,
                         position_name: record.position_name,
                         nominal_amount: Number(record.nominal_amount).toLocaleString(),
                         nominal_amount_raw: record.nominal_amount,
                         currency: record.currency,
                         cost_price: currencyFormatter.format(record.cost_price),
                         cost_price_raw: record.cost_price,
                         price: currencyFormatter.format(record.price),
                         price_raw: record.price,
                         profit_loss: (record.profit_loss * 100).toFixed(2) + '%',
                         profit_loss_raw: record.profit_loss,
                         usd_amount: currencyFormatter.format(record.usd_amount),
                         usd_amount_raw: record.usd_amount,
                         percent: percent.toFixed(2) + '%',
                         percent_raw: percent,
                         quantity: record.quantity
                     };

                     newRecord[this.viewBy] = record[this.viewBy];
                     newData.push(newRecord)
                 });

                return newData

            },
            exportData() {
                let exportData = []
                let data = this.chartFilteredData.slice()
                let viewByName = this.viewByNameMap[this.viewBy]
                data.forEach(o => {
                    let newObject = {
                        'Macro Category': o.macro_category
                    }
                    newObject[viewByName] = o[this.viewBy]

                    if (this.viewBy === 'category') {
                        newObject['SubCategory'] = o.sub_category
                    }

                    newObject = {
                        ...newObject,
                        'ISIN/CUSIP': o.isin,
                        'Position Name': o.position_name,
                        'Account': o.portfolio
                    }

                    if (this.viewBy !== 'currency'){
                        newObject['Currency'] = o.currency
                    }

                    let totalValue = o.macro_category.toUpperCase() === 'ASSET' ? this.totalAssetValue : this.totalLiabilityValue

                    newObject = {
                        ...newObject,
                        'Nominal Amount': o.nominal_amount.toFixed(2),
                        'Cost Price': (o.cost_price || 0).toFixed(2),
                        'Price': (o.price || 0).toFixed(2),
                        'P/L': (o.profit_loss || 0).toFixed(4),
                        'USD Value': o.usd_amount.toFixed(2),
                        '% of Portfolio': (totalValue === 0 ? 0 : o.usd_amount / totalValue).toFixed(4)
                    }

                    exportData.push(newObject)
                })
                return exportData
            }

        }
    }
</script>

<style scoped>
.positive-pl{
    color: #4CAF50;
}

.negative-pl{
    color: #F44336;
}

>>> .v-data-table-header th.desc .v-data-table-header__icon {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

>>> .v-data-table-header th.asc .v-data-table-header__icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

>>> .v-data-table-header th:hover:not(.desc):not(.asc) .v-data-table-header__icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
</style>